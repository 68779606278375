@use '@angular/material' as mat;

mat-chip {
  &.chip-no-outline {
    @include mat.chips-overrides(
      (
        outline-width: 0px,
        flat-selected-outline-width: 0px,
      )
    );
  }

  &.primary {
    --mdc-chip-elevated-container-color: var(--mat-sys-primary-container);
    --mdc-chip-label-text-color: var(--mat-sys-primary);
  }
  &.primary-text {
    --mdc-chip-label-text-color: var(--mat-sys-primary);
  }
}
