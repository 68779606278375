@use '../variables.scss' as *;

mat-icon {
  &.danger {
    --mat-icon-color: var(--mat-sys-error);
  }

  &.warning {
    --mat-icon-color: #{$review};
  }

  &.success {
    --mat-icon-color: #{$success};
  }
}
