// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
/* You can add global styles to this file, and also import other style files */

@use './variables';
@use './theme';
@use './shadow.scss';

@use './material/mat-chips';
@use './material/mat-list';
@use './material/mat-divider';
@use './material/mat-menu';
@use './material/mat-icon';
@use './material/mat-checkbox';
@use './material/mat-button';

@use './sizing.scss';
@use './color.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;
