@use '@angular/material' as mat;

html {
  @include mat.theme(
    (
      color: (
        theme-type: light,
        primary: mat.$azure-palette,
        tertiary: mat.$blue-palette,
      ),
      typography: Roboto,
      density: 0,
    )
  );
  // custom value from theme
  & {
    --mat-divider-width: 2px;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

h1,
h2 {
  font-weight: 400;
}

code,
pre {
  font-family: 'Roboto Mono', monospace;
}

code {
  font-size: 90%;
}

pre {
  font-size: 14px;
}

input[type='number'] {
  text-align: right;
}
