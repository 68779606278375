mat-checkbox {
  &.full-width {
    div:first-child {
      width: 100%;
    }
    .mdc-label {
      width: 100%;
    }
  }
}
